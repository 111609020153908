import React, { useState } from 'react';
import { NavLink as MantineNavLink, Box, Flex } from '@mantine/core';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    IconPhoto,
    IconFaceId,
    IconSeeding,
    IconBinaryTree,
    IconHandClick,
    IconPhotoX,
    IconUpload,
    IconProgressCheck,
    IconCalendarPlus,
    IconChevronRight,
    IconChevronDown
} from '@tabler/icons-react';

interface MenuItem {
    icon: React.ElementType;
    label: string;
    path: string;
    description?: string;
    rightSection?: React.ReactNode;
    rightSectionExpanded?: React.ReactNode;
    children?: MenuItem[];
}

const menuData: MenuItem[] = [
    { icon: IconPhoto, label: 'Gallery', path: '/gallery' },
    { icon: IconSeeding, label: 'Family Tree', path: '/family-tree' },
    { icon: IconFaceId, label: 'Identify Faces', path: '/identify-faces' },
    { icon: IconPhotoX, label: 'Remove Duplicates', path: '/remove-duplicates' },
    {
        icon: IconProgressCheck,
        label: 'Sort Images',
        path: '/sort-images',
        description: 'Organize photos with AI'
    },
    // Uncomment and modify if you want to restore the nested menu structure
    // {
    //     icon: IconProgressCheck,
    //     label: 'Sort Images',
    //     path: '/sort-images',
    //     description: 'Organize photos with AI',
    //     rightSection: <IconChevronRight size="1rem" stroke={1.5} />,
    //     rightSectionExpanded: <IconChevronDown size="1rem" stroke={1.5} />,
    //     children: [
    //         { icon: IconBinaryTree, label: 'AI Event Groups', path: '/ai-event-groups' },
    //         { icon: IconHandClick, label: 'Select Event Groups', path: '/select-event-groups' },
    //         { icon: IconCalendarPlus, label: 'Confirm Dates', path: '/confirm-dates' },
    //     ],
    // },
];

export const LeftNavMenu: React.FC = () => {
    const [active, setActive] = useState<number | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    const handleItemClick = (index: number, path: string) => {
        setActive(index === active ? null : index);
        navigate(path);
    };

    const renderMenuItem = (item: MenuItem, index: number) => (
        <Box key={item.label}>
            <MantineNavLink
                active={location.pathname === item.path}
                label={item.label}
                description={item.description}
                rightSection={index === active ? item.rightSectionExpanded : item.rightSection}
                leftSection={<item.icon size="2rem" stroke={1.5} />}
                onClick={() => handleItemClick(index, item.path)}
                variant="light"
            />
            {active === index && item.children && (
                <Box ml={16}>
                    {item.children.map(renderChildItem)}
                </Box>
            )}
        </Box>
    );

    const renderChildItem = (child: MenuItem) => (
        <MantineNavLink
            key={child.label}
            label={child.label}
            description={child.description}
            rightSection={child.rightSection}
            leftSection={<child.icon size="1rem" stroke={1.5} />}
            onClick={() => navigate(child.path)}
            variant="light"
            active={location.pathname === child.path}
        />
    );

    return (
        <Flex direction="column" justify="space-between" style={{ height: '100%' }}>
            <Box w="100%">
                {menuData.map(renderMenuItem)}
            </Box>
            <Box w="100%" mt="auto" pb="md">
                <MantineNavLink
                    key="Import"
                    label="Import"
                    leftSection={<IconUpload size="2rem" stroke={1.5} />}
                    onClick={() => navigate('/import')}
                    variant="filled"
                    active={location.pathname === '/import'}
                    styles={(theme) => ({
                        root: {
                            borderRadius: theme.radius.md,
                            '&:not(:last-of-type)': {
                                borderBottom: 'none',
                            },
                        },
                        label: {
                            fontWeight: 'bold',
                        },
                    })}
                />
            </Box>
        </Flex>
    );
};