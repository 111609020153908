import axios from 'axios';
import { AuthenticationResultType } from "@aws-sdk/client-cognito-identity-provider";
import { Face, FaceStack } from "../../types/Face";
import { Person, PersonBetter } from "../../types/Person";
import { DuplicateImageGroup } from "../../types/ImageData";

const API_BASE_URL = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production";

export const PersonFaceAPI = {
    getAuthHeaders: (auth: AuthenticationResultType) => ({
        'Authorization': `Bearer ${auth.IdToken}`,
        'Content-Type': 'application/json'
    }),

    fetchPeople: async (userId: string, auth: AuthenticationResultType): Promise<PersonBetter[]> => {
        try {
            const response = await axios.get(`${API_BASE_URL}/person`, {
                params: { sub: userId },
                headers: PersonFaceAPI.getAuthHeaders(auth)
            });

            const responseData = typeof response.data.body === 'string'
                ? JSON.parse(response.data.body)
                : response.data.body;

            return responseData.map((member: Person) => ({
                seed_key: member.id,
                first: member.firstName,
                last: member.lastName,
                dob: member.dateOfBirth,
            }));
        } catch (error) {
            console.error('Error fetching people', error);
            throw error;
        }
    },

    fetchFacesInImages: async (imageKeys: string[], userId: string, auth: AuthenticationResultType): Promise<Face[]> => {
        try {
            const response = await axios.post(`${API_BASE_URL}/faces/in-images`,
                {
                    sub: userId,
                    image_keys: imageKeys
                },
                {
                    headers: PersonFaceAPI.getAuthHeaders(auth)
                }
            );

            return JSON.parse(response.data.body) || [];
        } catch (error) {
            console.error('Error fetching faces', error);
            throw error;
        }
    },

    fetchDuplicates: async (userId: string, auth: AuthenticationResultType, selectedImages: string[]): Promise<DuplicateImageGroup[]> => {
        try {
            const response = await axios.post(
                `${API_BASE_URL}/duplicates/in-images`,
                {
                    sub: userId,
                    image_keys: selectedImages
                },
                {
                    headers: PersonFaceAPI.getAuthHeaders(auth)
                }
            );

            return typeof response.data.body === 'string'
                ? JSON.parse(response.data.body)
                : response.data.body;
        } catch (error) {
            console.error('Error fetching duplicates', error);
            throw error;
        }
    },

    manageFace: async (
        userId: string,
        auth: AuthenticationResultType,
        personKey: string,
        faceKeys: string[],
        action: 'confirm' | 'deny' | 'untrack' | 'label'
    ) => {
        try {
            const response = await axios.patch(`${API_BASE_URL}/faces`, {
                uid: userId,
                person_key: personKey,
                face_keys: faceKeys,
                action,
            }, {
                headers: PersonFaceAPI.getAuthHeaders(auth)
            });

            return response.data;
        } catch (error) {
            console.error(`Error ${action}ing face:`, error);
            throw error;
        }
    },

    addPerson: async (
        userId: string,
        auth: AuthenticationResultType,
        firstName: string,
        lastName: string,
        dateOfBirth: string,
        faceKey: string
    ): Promise<PersonBetter> => {
        try {
            const response = await axios.post(
                `${API_BASE_URL}/person`,
                {
                    uid: userId,
                    first_name: firstName,
                    last_name: lastName,
                    dob: dateOfBirth,
                    key: faceKey
                },
                {
                    headers: PersonFaceAPI.getAuthHeaders(auth)
                }
            );

            return JSON.parse(response.data.body);
        } catch (error) {
            console.error('Error adding family member:', error);
            throw error;
        }
    },

    mergeDuplicates: async (userId: string, auth: AuthenticationResultType, duplicateGroupKey: string) => {
        try {
            await axios.post(
                `${API_BASE_URL}/duplicates`,
                {
                    sub: userId,
                    key: duplicateGroupKey
                },
                {
                    headers: PersonFaceAPI.getAuthHeaders(auth)
                }
            );
        } catch (error) {
            console.error('Error merging duplicates:', error);
            throw error;
        }
    }
};

