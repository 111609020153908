import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    useDisclosure,
    useMediaQuery
} from '@mantine/hooks';
import { AppShell, Stack, Burger, Flex, Center, Container, MantineProvider, Modal, Avatar, Menu, UnstyledButton, Text } from '@mantine/core';
import { LeftNavMenu } from './components/LeftNavMenu';
import TextComponent from './components/Text';
import './logo-font.css';
import '@mantine/carousel/styles.css';
import { IconChevronDown, IconSettings, IconCreditCard, IconLogout} from '@tabler/icons-react';
import {FamilyTree} from './components/familyTree/FamilyTree';
import SortOptionsMenu from './components/sortOptionsMenu';
import DeleteDuplicates from './components/removeDuplicates/DeleteDuplicates';
import ConfirmDates from './components/ConfirmDates';
import { Gallery } from './components/Gallery';
import { NewIdentifyFaces } from './components/identifyFaces/NewIdentifyFaces';
import { GroupImages } from './components/aiGroupImages/GroupImages';
import { CommunityGallery } from './components/CommunityGallery';
import { FileUploader } from './components/FileUploader';
import { AuthenticationTitle, AuthStateOptions } from './components/authentication/Authenticate';
import {
    AuthenticationResultType,
    GetUserCommand, GetUserCommandInput,
    GetUserCommandOutput
} from '@aws-sdk/client-cognito-identity-provider';
import { LandingPage } from './landing/LandingPage';
import { Header } from './landing/Header'
import {GroupImagesManually} from "./components/sortImages/GroupImagesManually";
// import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider';
import { CognitoIdentityProviderClient, InitiateAuthCommand } from '@aws-sdk/client-cognito-identity-provider';
import axios from "axios";
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useLocation} from 'react-router-dom';
// New auth-related component imports
import { AuthCallback } from './components/authentication/AuthCallback';
import Login from './components/authentication/Login';
import Signup from './components/authentication/SignUp';
import {NewDeviceMetadataType} from "@aws-sdk/client-cognito-identity-provider/dist-types/models/models_0";

// todo: clean up the above imports!!

// Read environment variables
const client = new CognitoIdentityProviderClient({
    region: process.env.REACT_APP_AWS_REGION || 'us-west-2'
});

function useSessionStorage(key: string) {
    const [value, setValue] = useState<string | null>(() => sessionStorage.getItem(key));

    useEffect(() => {
        const handleStorageChange = () => {
            const newValue = sessionStorage.getItem(key);
            setValue(newValue);
        };

        // Add event listener for storage changes
        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [key]);

    return value;
}

// Custom hook to handle back button
const useBackButton = (callback: () => void) => {
    useEffect(() => {
        window.history.pushState(null, '', window.location.pathname);
        window.addEventListener('popstate', callback);
        return () => {
            window.removeEventListener('popstate', callback);
        };
    }, [callback]);
};

// todo: delete?
// Types for refresh token handling
interface TokenData {
    auth: AuthenticationResultType;
    expiresAt: number;
}

// todo: fix this up so its usable, maybe this is where i should be verifying that users are authenticated?
// Custom hook for token management
const useAuthTokens = () => {
    const [auth, setAuth] = useState<AuthenticationResultType | null>(null);

    const [isRefreshing, setIsRefreshing] = useState(false);

    const cognitoClient = new CognitoIdentityProviderClient({
        region: process.env.REACT_APP_AWS_REGION || 'us-west-2'
    });

    // const refreshTokens = async (refreshToken: string) => {
    const refreshTokens = async () => {
        console.log("trying to refresh token!!!")
        try {
            // Simple POST request with credentials
            const response = await fetch('https://api.sortmyshoebox.com/auth/refresh-token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // Include cookies in the request and handle Set-Cookie in the response
                // todo: add session.sub to input param body?
            })
                .then((response) => {
                    if (response.ok) {
                        console.log('Cookie set successfully!');
                    } else {
                        console.error('Failed to set cookie:', response.status);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

            console.log("token refresh successful!");
        } catch (err) {
            console.error('Error during token refresh:', err);
        }

    };

    const updateTokens = (newAuth: AuthenticationResultType) => {
        // Store tokens with expiration time (default 1 hour)
        const expiresAt = Date.now() + 3600 * 1000;
        localStorage.setItem('tokenData', JSON.stringify({
            auth: newAuth,
            expiresAt
        }));
        setAuth(newAuth);
    };

    // Check and refresh tokens if needed
    const checkAndRefreshTokens = useCallback(async () => {
        // Get the expiration time from sessionStorage (in seconds)
        const accessTokenExpiresAt = Number(sessionStorage.getItem('accessTokenExpiresAt')); // returns seconds since epoch
        const secondsUntilExpiry = accessTokenExpiresAt - Date.now();
        console.log("seconds until expiration: ", secondsUntilExpiry);
        if (secondsUntilExpiry < 300) {
            console.log("token expires in less than 5 minutes. Refreshing!")
            try {
                let response = await fetch('https://api.sortmyshoebox.com/auth/refresh-token', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include', // allows the cookie to be set on successful sign in
                    body: JSON.stringify({sub: localStorage.getItem('sub')}),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    console.log(errorData);
                }

                if (response.ok) {
                    try {
                        const data = await response.json();

                        // Store access token in sessionStorage
                        if (data.accessToken && data.expiresIn) {
                            sessionStorage.setItem('accessToken', data.accessToken);
                            const expiresAt = Date.now() + data.expiresIn * 1000;
                            console.log(expiresAt);
                            sessionStorage.setItem('accessTokenExpiresAt', expiresAt.toString());
                        } else {
                            console.warn("accessToken is missing from the response body.");
                        }
                        sessionStorage.setItem('email', data.userAttributes.email);
                        sessionStorage.setItem('firstName', data.userAttributes.firstName);
                        sessionStorage.setItem('lastName', data.userAttributes.lastName);
                        sessionStorage.setItem('profileUrl', data.userAttributes.picture);
                        // setAuthenticated(true);

                    } catch (error) {
                        console.error("Error processing response data:", error);
                        return false;
                        // setAuthenticated(false);
                    }
                } else {
                    console.log('Token exchange failed');
                    return false;
                    // setAuthenticated(false);
                }
            } catch (error) {
                console.error('Failed to refresh token:', error);
                return false;
                // setAuthenticated(false);
            }
        }
        return true;
    }, []);

    // Initialize tokens on mount and set up refresh interval
    useEffect(() => {
        checkAndRefreshTokens();

        // Check tokens every minute
        const interval = setInterval(checkAndRefreshTokens, 60000);
        return () => clearInterval(interval);
    }, [checkAndRefreshTokens]);

    return {
        auth,
        isRefreshing,
        updateTokens,
        checkAndRefreshTokens
    };
};


// todo: this function is desparently in need of some cleanup.
export default function App() {
    // const [opened, { toggle }] = useDisclosure();
    // // todo: redo authenticated logic to setAuthenticated to true when there is a AccessToken in session storage and false when there is not.
    // //  before ever setting authentiated false, i should attempt to make a call to api.sortmyshoebox.com/auth/refresh-token and then check again if AccessToken exist before setting authetnicated to false.
    // const [authenticated, setAuthenticated] = useState(false);
    // const [user, setUser] = useState<GetUserCommandOutput>();
    // const [authAction, setAuthAction] = useState<AuthStateOptions | null>(null);
    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const extraPadding = useMediaQuery('(min-width: 1440px)');

    const { auth, isRefreshing, updateTokens, checkAndRefreshTokens } = useAuthTokens();

    // const containerRef = useRef<HTMLDivElement>(null); // todo: possibly delete?

    // const handleMenuItemClick = (component: string) => {
    //     setCurrentComponent(component);
    // };

    // const { auth, isRefreshing, updateTokens, checkAndRefreshTokens } = useAuthTokens();
    // const [authenticated, setAuthenticated] = useState(false);
    // const [user, setUser] = useState<GetUserCommandOutput>();
    const [opened, { toggle }] = useDisclosure();
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState<GetUserCommandOutput>();
    const [authAction, setAuthAction] = useState<AuthStateOptions | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const extraPadding = useMediaQuery('(min-width: 1440px)');

    // Use the custom hook to monitor changes to the AccessToken
    const accessToken = useSessionStorage('accessToken');

    // todo: i also want to check this each time a tab is revisited of the user has been away? Not sure if this is possible?
    // todo: question, when is this function triggered? on load & when accessToken is modified or just on load? I want to use the logic to recheck for authentication to happen whenever a user first visists the page and if possible if the returns to the page after being in a differnt tab?
    //  also, for less redundancy, could i extract this checkAuthentication logic so it is more resusable?\
    useEffect(() => {
        const checkAuthentication = async () => {
            // todo: get & parse sessionStorage.get('accessTokenExpiresAt') to a datetime
            if (accessToken) { // todo: and access token is not expired & localStorage.sub exists.
                // If token exists, user is authenticated
                setAuthenticated(true);
            } else {
                // todo: if access token is expired and refresh doesnt work, delete the accessToken we have stored for the session.
                // Try refreshing the token
                try {
                    let response = await fetch('https://api.sortmyshoebox.com/auth/refresh-token', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include', // allows the cookie to be set on successful sign in
                        body: JSON.stringify({sub: localStorage.getItem('sub')}),
                    });

                    if (!response.ok) {
                        const errorData = await response.json();
                        console.log(errorData);
                    }

                    if (response.ok) {
                        try {
                            const data = await response.json();

                            // Store access token in sessionStorage
                            if (data.accessToken && data.expiresIn) {
                                sessionStorage.setItem('accessToken', data.accessToken);
                                const expiresAt = Date.now() + data.expiresIn * 1000;
                                console.log(expiresAt);
                                sessionStorage.setItem('accessTokenExpiresAt', expiresAt.toString());
                            } else {
                                console.warn("accessToken is missing from the response body.");
                            }
                            sessionStorage.setItem('email', data.userAttributes.email);
                            sessionStorage.setItem('firstName', data.userAttributes.firstName);
                            sessionStorage.setItem('lastName', data.userAttributes.lastName);
                            sessionStorage.setItem('profileUrl', data.userAttributes.picture);
                            setAuthenticated(true);

                        } catch (error) {
                            console.error("Error processing response data:", error);
                            setAuthenticated(false);
                        }
                    } else {
                        console.log('Token exchange failed');
                        setAuthenticated(false);
                    }
                } catch (error) {
                    console.error('Failed to refresh token:', error);
                    setAuthenticated(false);
                }
            }
        };

        // Re-run authentication check whenever AccessToken changes
        checkAuthentication();
    }, [accessToken]);

    // Axios interceptor for automatic token refresh
    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            response => response,
            async error => {
                const originalRequest = error.config;

                if (error.response?.status === 401 && !originalRequest._retry) {
                    console.log("401 Error detected!");
                    originalRequest._retry = true;

                    try {
                        const accessTokenObtained = await checkAndRefreshTokens();
                        if (accessTokenObtained && sessionStorage.getItem('accessToken') != null) {
                            originalRequest.headers['Authorization'] = `Bearer ${sessionStorage.getItem('accessToken')}`;
                            return axios(originalRequest);
                        }
                    } catch (refreshError) {
                        setAuthenticated(false);
                        return Promise.reject(refreshError);
                    }
                }
                return Promise.reject(error);
            }
        );

        return () => axios.interceptors.response.eject(interceptor);
    }, [checkAndRefreshTokens]);

    // todo: check for usage, then delete
    const onSuccessfulSignOn = (authResults: AuthenticationResultType, userResults: GetUserCommandOutput) => {
        console.warn("Using function marked for deletion: const onSuccessfulSignOn = (authResults: AuthenticationResultType, userResults: GetUserCommandOutput) => {")
        setAuthenticated(true);
        updateTokens(authResults);
        setUser(userResults);
    };

    // todo: check for usage, then delete
    const onSuccessfulSignIn = async () => {
        console.warn("Using function marked for deletion: const onSuccessfulSignIn = async () => {")
        setAuthenticated(true);
        console.log(localStorage.getItem('accessToken'));
        const getUserInput : GetUserCommandInput = {
            AccessToken: localStorage.getItem('accessToken') || ""
        }
        const getUsers = new GetUserCommand(getUserInput);
        const userResults: GetUserCommandOutput = await client.send(getUsers);
        console.log(userResults);
        setUser(userResults);
    }

    // todo: not sure what this is doing? check for usage, then delete
    const handleAuthAction = (authState: AuthStateOptions.Login | AuthStateOptions.JoinWaitlist) => {
        console.warn("Using function marked for deletion: const handleAuthAction = (authState: AuthStateOptions.Login | AuthStateOptions.JoinWaitlist) => {")
        console.log(authState);
        setAuthAction(authState);
        setIsModalOpen(true);  // Open the modal when auth action is triggered
        window.history.pushState(null, '', window.location.pathname);  // Add a new history entry
    };

    // Handle back button press
    useBackButton(() => {
        if (isModalOpen) {
            setIsModalOpen(false);
            setAuthAction(null);
        }
    });

    const Logo = () => (
        <svg width="200" height="30" viewBox="0 0 200 30">
            <text
                x="0"
                y="25"
                fontFamily="Oswald, sans-serif"
                fontSize="24"
                fontWeight="500"
                fill="currentColor"
            >
                SORT MY SHOEBOX
            </text>
        </svg>
    );

    // todo: can i refactor all the user menu stuff to a new file?
    const UserMenu = () => {
        const firstName = localStorage.getItem('firstName') || '';
        const lastName = localStorage.getItem('lastName') || '';
        const email = localStorage.getItem('email') || '';
        const profileUrl = localStorage.getItem('profileUrl');

        const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;

        const handleLogout = async () => {
            try {
                const response = await fetch('https://api.sortmyshoebox.com/auth/sign-out', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                })
                console.log(response);
            } catch (e) {
                console.log(e);
            }

            console.log('Logging out...');
        };

        return (
            <Menu position="bottom-end" shadow="md" width={300}>
                <Menu.Target>
                    <UnstyledButton>
                        <Flex align="center" gap="md">
                            <Avatar
                                src={profileUrl || null}
                                color="gray"
                                radius="xl"
                                size="md"
                            >
                                {!profileUrl && initials}
                            </Avatar>
                            <Text className="oswald-username" size="sm">
                                {`${firstName} ${lastName}`.toUpperCase()}
                            </Text>
                            <IconChevronDown size="1.2rem" stroke={1.5} />
                        </Flex>
                    </UnstyledButton>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Label>
                        <Flex direction="column">
                            <Text size="sm">Signed in as</Text>
                            <Text size="sm" fw={500}>{email}</Text>
                        </Flex>
                    </Menu.Label>

                    <Menu.Divider />

                    <Menu.Item leftSection={<IconSettings size="1rem" />}>
                        Settings
                    </Menu.Item>

                    <Menu.Item leftSection={<IconCreditCard size="1rem" />}>
                        Manage Subscription
                    </Menu.Item>

                    <Menu.Divider />

                    <Menu.Item
                        color="red"
                        leftSection={<IconLogout size="1rem" />}
                        onClick={handleLogout}
                    >
                        Logout
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        );
    };

    // todo: how difficult to factor this to a seperate file?
    const AuthenticatedLayout = () => {
        const [opened, setOpened] = useState(false);
        const extraPadding = window.innerWidth > 1200;

        const toggle = () => setOpened((o) => !o);

        return (
            <AppShell
                header={{height: 85}}
                navbar={{
                    width: {base: '100%', sm: '300px'},
                    breakpoint: 'sm',
                    collapsed: {mobile: !opened},
                }}
                padding="md"
            >
                <AppShell.Header>
                    <Container size="100%" h="100%">
                        <Flex
                            justify="space-between"
                            align="center"
                            h="100%"
                            ml={extraPadding ? "10vw" : "10px"}
                            mr={extraPadding ? "10vw" : "10px"}
                        >
                            <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm"/>

                            <Flex align="center" gap="md">
                                <Logo/>
                            </Flex>

                            <UserMenu/>
                        </Flex>
                    </Container>
                </AppShell.Header>

                <AppShell.Navbar p="md" style={{
                    marginLeft: extraPadding ? "10vw" : "10px",
                    borderRight: '1px solid #ccc'
                }}>
                    <Flex direction="column"
                          style={{height: '100%', justifyContent: 'flex-start', alignItems: 'flex-end'}}>
                        <LeftNavMenu/>
                    </Flex>
                </AppShell.Navbar>

                <AppShell.Main>
                    <Container size="100%" style={{
                        marginLeft: extraPadding ? "10vw" : "10px",
                        marginRight: extraPadding ? "10vw" : "10px"
                    }}>
                        <Routes>
                            <Route path="/gallery" element={<Gallery userId={user?.Username || ''} auth={auth!}/>}/>
                            <Route path="/family-tree"
                                   element={<FamilyTree userId={user?.Username || ''} auth={auth!}/>}/>
                            <Route path="/sort-images"
                                   element={<GroupImagesManually userId={user?.Username || ''} auth={auth!}/>}/>
                            <Route path="/remove-duplicates"
                                   element={<DeleteDuplicates userId={user?.Username || ''} auth={auth!}/>}/>
                            <Route path="/confirm-dates"
                                   element={<ConfirmDates userId={user?.Username || ''} auth={auth!}/>}/>
                            <Route path="/identify-faces"
                                   element={<NewIdentifyFaces userId={user?.Username || ''} auth={auth!}/>}/>
                            <Route path="/verify-groupings"
                                   element={<CommunityGallery userId={user?.Username || ''} auth={auth!}/>}/>
                            <Route path="/import" element={<FileUploader userId={user?.Username || ''} auth={auth!}
                                                                         idToken={auth?.IdToken}/>}/>
                            <Route path="/ai-event-groups"
                                   element={<GroupImages userId={user?.Username || ''} auth={auth!}/>}/>
                            <Route path="/select-event-groups"
                                   element={<GroupImagesManually userId={user?.Username || ''} auth={auth!}/>}/>
                            <Route path="/" element={<Navigate to="/gallery" replace/>}/>
                        </Routes>
                    </Container>
                </AppShell.Main>
            </AppShell>
        );
    };

    // todo: how difficult to factor this to a seperate file?
    const UnauthenticatedLayout = () => (
        <div>
            {authAction == null ? (
                <LandingPage onAuthAction={handleAuthAction} />
            ) : (
                <>
                    <Header
                        onLoginClick={() => handleAuthAction(AuthStateOptions.Login)}
                        onRegisterClick={() => handleAuthAction(AuthStateOptions.JoinWaitlist)}
                    />
                    <Modal
                        opened={isModalOpen}
                        onClose={() => {
                            setIsModalOpen(false);
                            setAuthAction(null);
                        }}
                        title={authAction === AuthStateOptions.Login ? "Welcome Back!" : "Join Waitlist"}
                        size="md"
                        centered
                    >
                        <AuthenticationTitle
                            onSuccessfulSignIn={onSuccessfulSignOn}
                            initialAuthState={authAction}
                        />
                    </Modal>
                </>
            )}
        </div>
    );

    return (
        <MantineProvider>
            <BrowserRouter>
                {authenticated ? (
                    <AuthenticatedLayout />
                ) : (
                    <Routes>
                        <Route
                            path="/login"
                            element={
                                <AuthenticationTitle
                                    onSuccessfulSignIn={onSuccessfulSignOn}
                                    initialAuthState={AuthStateOptions.Login}
                                />
                            }
                        />
                        <Route
                            path="/signup"
                            element={
                                <AuthenticationTitle
                                    onSuccessfulSignIn={onSuccessfulSignOn}
                                    initialAuthState={AuthStateOptions.Register}
                                />
                            }
                        />
                        <Route
                            path="/waitlist"
                            element={
                                <AuthenticationTitle
                                    onSuccessfulSignIn={onSuccessfulSignOn}
                                    initialAuthState={AuthStateOptions.JoinWaitlist}
                                />
                            }
                        />
                        <Route path="*" element={<Navigate to="/login" replace />} />
                        <Route
                            path="/login/callback"
                            element={
                                <AuthCallback
                                    onSuccessfulSignIn={onSuccessfulSignIn}
                                />
                            }
                        />
                    </Routes>

                )}
            </BrowserRouter>
        </MantineProvider>
    );
}