import React, { useEffect, useState, useRef} from 'react';
import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider';
import { Blockquote, Button, Divider, Flex, Title, Text, Paper, Box, Modal, Group, Skeleton, Space} from '@mantine/core';
import { IconInfoCircle, IconArrowRight } from '@tabler/icons-react';
import { ImageSelectionGrid } from './ImageSelectionGrid';
import { EventDetailsForm } from './EventDetailsForm';
import { ConfirmFacesModalContent } from './ConfirmFacesForEvent';
import { ImageData } from '../../types/ImageData';
import * as imageAPI from '../../api/imageAPI';
import ImageWithSkeleton from "../ImageWithSkeleton";
import {RemoveDuplicatesModal} from './RemoveDuplicateModal';
import axios from "axios";
import {
    GroupSuggestion,
    SuggestionNewEvent
} from "../../types/GroupInterfaces";
import {NewEvent} from "../aiGroupImages/GroupNewEvent";
import { PersonFaceAPI } from './API';
import { Face } from "../../types/Face";
import { PersonBetter } from "../../types/Person";
import { DuplicateImageGroup } from "../../types/ImageData";

interface GroupImagesManuallyProps {
    userId: string;
    auth: AuthenticationResultType;
}

// todo: i want to rework the sub-components (i.e. remove duplicates & name faces so that those functions so not have to query the backend database and are instead passed callback to add/remove people.
export const GroupImagesManually = ({ userId, auth }: GroupImagesManuallyProps) => {
    // State management
    const [images, setImages] = useState<ImageData[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit] = useState(20);
    const [hasMore, setHasMore] = useState(true);
    const [selectedImages, setSelectedImages] = useState<string[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [modalPage, setModalPage] = useState(1);


    const [isLoadingAiPredictions, setIsLoadingAiPredictions] = useState(false);

    // duplicate images state
    const [duplicates, setDuplicates] = useState<DuplicateImageGroup[]>([]);
    const [isLoadingDuplicates, setIsLoadingDuplicates] = useState(false);

    // faces & people state
    const [people, setPeople] = useState<PersonBetter[]>([]);
    const [isLoadingPeople, setIsLoadingPeople] = useState(false);
    const [faces, setFaces] = useState<Face[]>([]);
    const [isLoadingFaces, setIsLoadingFaces] = useState(false);

    // Event details state
    const [eventDate, setEventDate] = useState<Date | null>(null);
    const [eventDescription, setEventDescription] = useState("");
    const [location, setLocation] = useState("");
    const [tags, setTags] = useState<string[]>([]);
    const [dateFormat, setDateFormat] = useState<'month' | 'day'>('month');
    const [isDateAI, setIsDateAI] = useState(false);
    const [isEventDescriptionAI, setIsEventDescriptionAI] = useState(false);
    const [isLocationAI, setIsLocationAI] = useState(false);

    // suggestions AI group
    const [suggestionsAiGroup, setSuggestionsAiGroup] = useState<GroupSuggestion[]>([]);
    const [isLoadingAiGroup, setIsLoadingAiGroup] = useState<boolean>(true);
    const [hasMoreSuggestionsAiGroup, setHasMoreSuggestionsAiGroup] = useState<boolean>(true);

    const [gridWidth, setGridWidth] = useState(0);
    const gridRef = useRef<HTMLDivElement>(null);

    // grahhhhh just ignoring the error because ive spent 4 hours on it and came up with nothing...
    // todo: fix actual issue and stop suppressing...
    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message.startsWith('ResizeObserver loop')) {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    // Only set up resize observer when modal is closed
    useEffect(() => {
        if (modalOpen) {
            return; // Don't observe when modal is open
        }

        const updateWidth = () => {
            if (gridRef.current) {
                setGridWidth(gridRef.current.offsetWidth);
            }
        };

        // Initial measurement
        updateWidth();

        // Set up resize observer
        const resizeObserver = new ResizeObserver(updateWidth);
        if (gridRef.current) {
            resizeObserver.observe(gridRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [modalOpen]); // Only re-run when modalOpen changes


    // Fetch images on mount and page change
    useEffect(() => {
        const fetchImages = async () => {
            const result = await imageAPI.withErrorHandling(
                () => imageAPI.fetchUngroupedImages(page, limit, userId, auth)
            );

            if (result) {
                setImages(prev => [...prev, ...result.images]);
                setHasMore(result.hasMore);
            }
        };

        fetchImages();
    }, [page]);

    // Handle AI predictions
    useEffect(() => {
        if (isDateAI && eventDate && eventDate.getDate() !== 1) {
            setDateFormat('day');
        }
    }, [isDateAI, eventDate]);

    const fetchAIPredictions = async () => {
        setIsLoadingAiPredictions(true);
        const result = await imageAPI.withErrorHandling(
            () => imageAPI.fetchAIPredictions(selectedImages, userId, auth)
        );

        if (result) {
            if (result.event_title) {
                setEventDescription(result.event_title);
                setIsEventDescriptionAI(true);
            }
            if (result.event_location) {
                setLocation(result.event_location);
                setIsLocationAI(true);
            }
            if (result.event_date) {
                setEventDate(new Date(result.event_date));
                setIsDateAI(true);
            }
            if (result.event_keywords) {
                setTags(result.event_keywords);
            }
        }
        setIsLoadingAiPredictions(false);
    };

    const fetchFacesInImages = async (imageKeys: string[]) => {
        setIsLoadingFaces(true);
        try {
            const faces = await PersonFaceAPI.fetchFacesInImages(imageKeys, userId, auth);
            setFaces(faces);
            return faces;
        } catch (error) {
            console.error('Failed to fetch faces:', error);
            return null;
        } finally {
            setIsLoadingFaces(false);
        }
    };

    const fetchPeopleData = async () => {
        setIsLoadingPeople(true);
        try {
            const people = await PersonFaceAPI.fetchPeople(userId, auth);
            setPeople(people);
            return people;
        } catch (error) {
            console.error('Failed to fetch people:', error);
            return null;
        } finally {
            setIsLoadingPeople(false);
        }
    };

    const fetchDuplicateImages = async (imageKeys: string[]) => {
        setIsLoadingDuplicates(true);
        try {
            const duplicates = await PersonFaceAPI.fetchDuplicates(userId, auth, imageKeys);
            setDuplicates(duplicates);
            return duplicates;
        } catch (error) {
            console.error('Failed to fetch duplicates:', error);
            return null;
        } finally {
            setIsLoadingDuplicates(false);
        }
    };

    // Event handlers
    const handleImageSelect = (imageKey: string) => {
        setSelectedImages(prev =>
            prev.includes(imageKey)
                ? prev.filter(key => key !== imageKey)
                : [...prev, imageKey]
        );
    };

    // todo: still feeling like this is not the best
    const handleCreateEvent = async () => {

        if (selectedImages.length === 0) return;
        setEventDate(null);
        setEventDescription('');
        setLocation('');
        setTags([]);

        setModalLoading(true);
        setModalOpen(true);

        try {
            // First, check for duplicates
            const duplicatesResult = await fetchDuplicateImages(selectedImages);

            // If we have duplicates, stay on the duplicates page (page 1)
            if (duplicatesResult && duplicatesResult.length > 0) {
                setModalPage(1);
                setModalLoading(false);
                // todo: needed?
                await Promise.all([
                    fetchPeopleData(),
                    fetchFacesInImages(selectedImages),
                    fetchAIPredictions()
                ]);
                return;
            }

            // If no duplicates, fetch people and faces in parallel
            const [peopleResult, facesResult] = await Promise.all([
                fetchPeopleData(),
                fetchFacesInImages(selectedImages)
            ]);

            // If we have faces to process, go to the faces confirmation page
            if (facesResult && facesResult.length > 0) {
                setModalPage(2);
                setModalLoading(false);
                await fetchAIPredictions();
                return;
            }

            // If no faces to process, fetch AI predictions and go to the event details page
            await fetchAIPredictions();
            setModalPage(3);

        } catch (error) {
            console.error('Error during event creation flow:', error);
            // In case of error, default to event details page
            setModalPage(3);
        } finally {
            setModalLoading(false);
        }
    };

    // Handle modal navigation
    const handleModalNext = async () => {
        const nextPage = modalPage + 1;
        setModalPage(nextPage);
    };

    const confirmCreateEvent = async () => {
        const result = await imageAPI.withErrorHandling(
            () => imageAPI.createEvent({
                userId,
                imageKeys: selectedImages,
                eventDate,
                eventDescription,
                location,
                tags
            }, auth)
        );

        if (result) {
            setImages(prev => prev.filter(image => !selectedImages.includes(image.key)));
            setSelectedImages([]);
            setModalOpen(false);
            setEventDate(null);
            setEventDescription('');
            setLocation('');
            setTags([]);
            setModalPage(1);
        }
    };

    const postNewEvent = async (seed: string, selected: string[], unselected: string[]): Promise<boolean> => {
        try {
            const newEventUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/event/new";
            const id_token = auth.IdToken;

            let data = {
                sub: userId,
                include: selected,
                exclude: unselected
            }
            console.log(data)

            const response = await axios.post(newEventUrl,
                {
                    sub: userId,
                    include: selected,
                    exclude: unselected
                },
                {
                    headers: {
                        'Authorization': `Bearer ${id_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status === 200) {
                return true;
            } else {
                console.error(`Backend Error: Status code ${response.status}, Response data: ${JSON.stringify(response.data)}`);
                return false;
            }
        } catch (error) {
            console.error('Error creating event:', error);
            return false;
        }
    };

    const closeNewEventSuggestionAfterSuccessfulSubmit = (suggestion: SuggestionNewEvent) => {
        return async (seed: string, selected: string[], unselected: string[]) => {
            const success = await postNewEvent(seed, selected, unselected);

            if (success) {
                if (suggestionsAiGroup.length === 1) {
                    // setIsLoadingAiGroup(true);
                    // await fetchAiGroupSuggestions();
                    //fetchSuggestionsFromDemo()
                } else {
                    setSuggestionsAiGroup(prevSuggestions => prevSuggestions.filter(s => s.key !== suggestion.key));
                }
                // setSuggestions(prevSuggestions => prevSuggestions.filter(s => s.key !== suggestion.key));
                // if (suggestions.length === 0) {
                //     fetchSuggestionsFromDemo()
                // }
            }
        };
    };

    const suggestionComponents = suggestionsAiGroup.map((suggestion) => {
        if (suggestion.type === 'newEvent') {
            return (
                <NewEvent key={suggestion.key} group={suggestion}
                          onSubmit={closeNewEventSuggestionAfterSuccessfulSubmit(suggestion)}/>
            )
        } else if (suggestion.type === 'addImageToEvent') {
            return
        } else if (suggestion.type === 'mergeEvents') {
            return
        } else {
            console.log("suggestion type not handled");
            return null;
        }
    }).filter(Boolean);

    const handleDuplicatesMerged = (mergedImageKeys: string[]) => {
        // Filter out faces that belong to merged images
        setFaces(prevFaces =>
            prevFaces.filter(face =>
                !mergedImageKeys.some(mergedKey => face.face_key.startsWith(mergedKey))
            )
        );
    };

    const handleNewPersonAdded = () => {
        // if a new person is added, this may provide more information to improve AI predictions, update the prediction.
        fetchAIPredictions();
    }


    const EventSuggestionSkeleton = () => {
        return (
            <div>
                {[...Array(1)].map((_, index) => (
                    <Paper shadow="sm" radius="lg" withBorder p="xl" key={index} mt={index > 0 ? "lg" : undefined}
                           style={{backgroundColor: '#f0f0f0'}}>
                        <Skeleton height={28} width="40%" radius="xl"/>
                        <Skeleton height={1} width="100%" mt="sm"/>
                        <Flex
                            mt="md"
                            direction="row"
                            wrap="wrap"
                            gap={{base: '3px', sm: '3px'}}
                            justify={{sm: '3px'}}
                        >
                            {[...Array(5)].map((_, imgIndex) => (
                                <Skeleton
                                    key={imgIndex}
                                    width="calc((100% - 12px) / 5)"
                                    height={0}
                                    style={{paddingBottom: 'calc((100% - 12px) / 5)'}}
                                    radius="sm"
                                />
                            ))}
                        </Flex>
                        <Group justify="flex-end" gap="sm" mt="lg">
                            <Skeleton height={36} width={80} radius="md"/>
                            <Skeleton height={36} width={120} radius="md"/>
                        </Group>
                    </Paper>
                ))}
            </div>
        );
    };

    return (
        <div>
            <Flex direction="column" style={{ width: '100%', minHeight: '100vh', position: 'relative' }}>
                <Title order={1}>Manually Group Images</Title>
                <Divider mt="xs" />
                <Blockquote color="blue" icon={<IconInfoCircle />} mt="lg" mb="lg">
                    Select images that belong to the same event and click "Create Event" to group them together.
                    This manual method gives you full control over how your images are organized.
                </Blockquote>
                {isLoadingAiGroup ? (
                    <EventSuggestionSkeleton/>
                ) : suggestionComponents.length > 0 ? (
                    suggestionComponents
                ) : (
                    <Paper p="md" withBorder>
                        <Title order={3}>No more suggestions available</Title>
                        <p>No AI suggestions available.</p>
                    </Paper>
                )}
                <Space h="lg" />
                <div ref={gridRef}>
                    <ImageSelectionGrid
                        images={images}
                        selectedImages={selectedImages}
                        onImageSelect={handleImageSelect}
                        loading={loading}
                        hasMore={hasMore}
                        onLoadMore={() => setPage(prev => prev + 1)}
                        enableInfiniteScroll={!modalOpen} // Disable infinite scroll when modal is open
                    />
                </div>

                <Box style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.75)',
                    position: 'fixed',
                    bottom: 0,
                    zIndex: 2,
                    width: gridWidth || '100%'
                }}>
                    <Flex justify="center">
                        <Button
                            mt="sm"
                            mb="sm"
                            size="md"
                            onClick={handleCreateEvent}
                            disabled={selectedImages.length === 0}
                            style={{width: '50%'}}
                        >
                            {selectedImages.length !== 0
                                ? `Create Event with ${selectedImages.length} Selected Image${selectedImages.length !== 1 ? 's' : ''}`
                                : 'Select Images to Create an Event'
                            }
                        </Button>
                    </Flex>
                </Box>
            </Flex>

            <Modal
                opened={modalOpen}
                size="80%"
                onClose={() => setModalOpen(false)}
                title="Create New Event"
                style={{
                    width: '80vw',
                    height: '80vh',
                    maxWidth: 'none',
                }}
            >
                {modalLoading && (
                    <>
                        <Title order={3} mb="md">Loading</Title>
                        <Text size="sm">loading...</Text>
                    </>
                )}
                {!modalLoading && !isLoadingDuplicates && modalPage === 1 && (
                    <>
                        <RemoveDuplicatesModal
                            userId={userId}
                            auth={auth}
                            duplicateGroups={duplicates}
                            onDuplicatesMerged={handleDuplicatesMerged}
                        />
                        <Button
                            mt="xl"
                            fullWidth
                            onClick={handleModalNext}
                            rightSection={<IconArrowRight size={14} />}
                        >
                            Next
                        </Button>
                    </>

                )}
                {!modalLoading && !isLoadingPeople && !isLoadingFaces && modalPage === 2 && (
                    <>
                        <ConfirmFacesModalContent
                            userId={userId}
                            auth={auth}
                            imageKeys={selectedImages}
                            people={people}
                            faces={faces}
                            onNewPersonAdded={handleNewPersonAdded}
                        />
                        <Button
                            mt="xl"
                            fullWidth
                            onClick={handleModalNext}
                            rightSection={<IconArrowRight size={14} />}
                        >
                            Next
                        </Button>
                    </>
                )}

                {!modalLoading && !isLoadingAiPredictions && modalPage === 3 && (
                    <>
                        <Paper shadow="sm" radius="lg" withBorder p="md" style={{ backgroundColor: '#f0f0f0' }}>
                            <Text fw={500}>{"Images Included in Event:"}</Text>
                            <Flex
                                mt="xs"
                                direction="row"
                                wrap="wrap"
                                gap={{ base: '3px', sm: '3px' }}
                                justify={selectedImages.length < 4 ? 'center' : 'flex-start'}
                            >
                                {selectedImages.slice(0, 3).map(key => (
                                    <div
                                        key={key}
                                        style={{
                                            position: 'relative',
                                            width: 'calc((100% - 9px) / 4)',
                                            aspectRatio: 1 / 1
                                        }}
                                    >
                                        <ImageWithSkeleton
                                            src={images.find(image => image.key === key)?.thumbnail_url || ''}
                                            alt="Thumbnail"
                                            onClick={() => {}}
                                        />
                                    </div>
                                ))}
                                {selectedImages.length > 3 && (
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: 'calc((100% - 9px) / 4)',
                                            aspectRatio: 1 / 1
                                        }}
                                    >
                                        <ImageWithSkeleton
                                            src={images.find(image => image.key === selectedImages[3])?.thumbnail_url || ''}
                                            alt="Thumbnail"
                                            onClick={() => {}}
                                        />
                                        {selectedImages.length > 4 && (
                                            <div style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: 'white',
                                                zIndex: 10
                                            }}>
                                                +{selectedImages.length - 4}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Flex>
                        </Paper>

                        <EventDetailsForm
                            eventDate={eventDate}
                            eventDescription={eventDescription}
                            location={location}
                            tags={tags}
                            dateFormat={dateFormat}
                            isDateAI={isDateAI}
                            isEventDescriptionAI={isEventDescriptionAI}
                            isLocationAI={isLocationAI}
                            isPredicting={isLoadingAiPredictions}
                            onDateChange={(date) => {
                                setEventDate(date);
                                setIsDateAI(false);
                            }}
                            onDescriptionChange={(value) => {
                                setEventDescription(value);
                                setIsEventDescriptionAI(false);
                            }}
                            onLocationChange={(value) => {
                                setLocation(value);
                                setIsLocationAI(false);
                            }}
                            onTagsChange={setTags}
                            onDateFormatChange={setDateFormat}
                            onConfirm={confirmCreateEvent}
                        />
                    </>
                )}
            </Modal>
        </div>
    );
};