import React, { useState } from 'react';
import { useForm } from '@mantine/form';
import { Flex, Button, Checkbox, Group, Stack, Text, TextInput, PasswordInput, Popover, Progress, Box} from '@mantine/core';
import { IconX, IconCheck } from '@tabler/icons-react';


interface PasswordChangeFormProps {
    onSubmit: (firstName: string, lastName: string, newPassword: string) => void;
}

const requirements = [
    { re: /[0-9]/, label: 'Includes number' },
    { re: /[a-z]/, label: 'Includes lowercase letter' },
    { re: /[A-Z]/, label: 'Includes uppercase letter' },
];

function PasswordRequirement({ meets, label }: { meets: boolean; label: string }) {
    return (
        <Text
            color={meets ? 'teal' : 'red'}
            style={{ display: 'flex', alignItems: 'center' }}
            mt={7}
            size="sm"
        >
            {meets ? <IconCheck style={{ width: 14, height: 14 }} /> : <IconX style={{ width: 14, height: 14 }} />} <Box ml={10}>{label}</Box>
        </Text>
    );
}

function getStrength(password: string) {
    let multiplier = password.length > 7 ? 0 : 1;

    requirements.forEach((requirement) => {
        if (!requirement.re.test(password)) {
            multiplier += 1;
        }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}


export function PasswordChangeForm({onSubmit}: PasswordChangeFormProps) {
    const [popoverOpened, setPopoverOpened] = useState(false);

    const form = useForm({
        initialValues: {
            firstName: '',
            lastName: '',
            newPassword: '',
            confirmPassword: '',
            terms: false,
        },
        validate: {
            firstName: (val) => (val.length ? null : 'First name is required'),
            lastName: (val) => (val.length ? null : 'Last name is required'),
            newPassword: (val) => (val.length >= 8 && /[a-z]/.test(val) && /[A-Z]/.test(val) && /[0-9]/.test(val) ? null : 'Password does not meet requirements'),
            confirmPassword: (val, values) => (val === values.newPassword ? null : 'Passwords do not match'),
            terms: (val) => (val ? null : 'You must accept terms and conditions'),
        },
    });

    const checks = requirements.map((requirement, index) => (
        <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(form.values.newPassword)} />
    ));

    const strength = getStrength(form.values.newPassword);
    const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';

    const handleSubmit = (values: { firstName: string; lastName: string; newPassword: string; confirmPassword: string; terms: boolean }) => {
            try {
                onSubmit(values.firstName, values.lastName, values.newPassword);
            } catch (error) {
               console.log(error)
            }
    }

    return (
        <>
            <Text size="xl" fw={500} mb="md">
                Please Set a New Password:
            </Text>
            <form onSubmit={form.onSubmit(handleSubmit)}>
                <Stack>
                    <Group grow align={"flex-start"}>
                        <TextInput
                            label="First Name"
                            withAsterisk
                            placeholder="Your first name"
                            value={form.values.firstName}
                            onChange={(event) => form.setFieldValue('firstName', event.currentTarget.value)}
                            error={form.errors.firstName}
                            radius="md"
                        />
                        <TextInput
                            label="Last Name"
                            withAsterisk
                            placeholder="Your last name"
                            value={form.values.lastName}
                            onChange={(event) => form.setFieldValue('lastName', event.currentTarget.value)}
                            error={form.errors.lastName}
                            radius="md"
                        />
                    </Group>
                    <Popover opened={popoverOpened} position="bottom" width="target" transitionProps={{ transition: 'pop' }}>
                        <Popover.Target>
                            <div
                                onFocusCapture={() => setPopoverOpened(true)}
                                onBlurCapture={() => setPopoverOpened(false)}
                            >
                                <PasswordInput
                                    label="New Password"
                                    withAsterisk
                                    placeholder="Your password"
                                    value={form.values.newPassword}
                                    onChange={(event) => form.setFieldValue('newPassword', event.currentTarget.value)}
                                    error={form.errors.newPassword}
                                    radius="md"
                                />
                            </div>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <Progress color={color} value={strength} size={5} mb="xs" />
                            <PasswordRequirement label="Includes at least 8 characters" meets={form.values.newPassword.length >= 8} />
                            {checks}
                        </Popover.Dropdown>
                    </Popover>
                    <PasswordInput
                        label="Confirm Password"
                        withAsterisk
                        placeholder="Confirm your password"
                        value={form.values.confirmPassword}
                        onChange={(event) => form.setFieldValue('confirmPassword', event.currentTarget.value)}
                        error={form.errors.confirmPassword}
                        radius="md"
                    />
                    <Checkbox
                        label="I accept terms and conditions"
                        checked={form.values.terms}
                        onChange={(event) => form.setFieldValue('terms', event.currentTarget.checked)}
                        error={form.errors.terms}
                    />
                    <Flex justify="flex-end">
                        <Button type="submit" radius="xl" color="cyan.8">
                            Change Password
                        </Button>
                    </Flex>
                </Stack>
            </form>
        </>
    );
}