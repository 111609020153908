import React, {useState} from 'react';
import {BackgroundImage, Center, Grid, Overlay, Paper, PaperProps} from '@mantine/core';
import {useMediaQuery} from '@mantine/hooks';
import * as AuthHandlers from './LoginHandlers';
import {PasswordChangeForm} from './PasswordChangeForm';
import Login from './Login';
import SignUp from './SignUp';
import Verification from './VerifyEmail';
import Waitlist from './Waitlist';
import {AuthenticationResultType, GetUserCommandOutput} from "@aws-sdk/client-cognito-identity-provider";
import {HandleSignIn} from './HandleSignIn';
import {useNavigate} from "react-router-dom";

export enum AuthStateOptions {
    Login = 0,
    Register = 1,
    ForgetPassword = 2,
    ResetPassword = 3,
    SetPasswordAtFirstLogin = 4,
    Verify = 5,
    LoginAfterRegistration = 6,
    JoinWaitlist = 7,
}

interface AuthenticationTitleProps extends PaperProps {
    onSuccessfulSignIn: (authResults: AuthenticationResultType, userResults: GetUserCommandOutput) => void;
    initialAuthState: AuthStateOptions; // Add this prop
}

export function AuthenticationTitle({ onSuccessfulSignIn, initialAuthState, ...props }: AuthenticationTitleProps) {
    const [username, setUsername] = useState("");
    const [session, setSession] = useState("");
    const [authState, changeAuthState] = useState<AuthStateOptions>(initialAuthState); // Use the prop for initial state
    const isMobile = useMediaQuery('(max-width: 768px)');
    const navigate = useNavigate();

    const handleEmailSignIn = async (email: string, password: string, setErrorMessage: (message: string) => void, clearForm: () => void) => {
        try {
            const response = await fetch('https://api.sortmyshoebox.com/auth/signin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // allows the cookie to be set on successful sign in
                body: JSON.stringify({email, password}),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.log(errorData);
                setErrorMessage(errorData.message || 'Incorrect username or password. Please try again.');
                clearForm();
                return;
            }

            if (response.ok) {
                try {
                    const data = await response.json();

                    if (data.sub) {
                        localStorage.setItem('sub', data.sub);
                    } else {
                        console.warn("sub is missing from the response body.");
                    }

                    // Store access token in sessionStorage
                    if (data.accessToken && data.expiresIn) {
                        sessionStorage.setItem('accessToken', data.accessToken);
                        const expiresAt = Date.now() + data.expiresIn * 1000;
                        console.log(expiresAt);
                        sessionStorage.setItem('accessTokenExpiresAt', expiresAt.toString());
                    } else {
                        console.warn("accessToken is missing from the response body.");
                    }

                    // todo: only do if these exist? otherwise set to some type of default?
                    sessionStorage.setItem('email', data.userAttributes.email);
                    sessionStorage.setItem('firstName', data.userAttributes.firstName);
                    sessionStorage.setItem('lastName', data.userAttributes.lastName);
                    sessionStorage.setItem('profileUrl', data.userAttributes.picture);
                    console.log("navigating to gallery?")
                    // onSuccessfulSignIn()
                    navigate('/gallery');

                } catch (error) {
                    console.error("Error processing response data:", error);
                }
            } else {
                throw new Error('Token exchange failed');
            }
        } catch (err) {
            setErrorMessage('Authentication failed. Please try again.');
            navigate('/login');
        }
    };

    const handleEmailSignUp = async (values: { firstName: string; lastName: string; email: string; password: string; terms: boolean }) => {
        // todo: make sure terms are agreed to

        await AuthHandlers.handleSignUp(values.email, values.password, values.firstName, values.lastName, setSession, setUsername, changeAuthState);
    };

    const handlePasswordChangeSubmit = async (firstName: string, lastName: string, newPassword: string) => {
        await AuthHandlers.handlePasswordChangeSubmit(session, username, firstName, lastName, newPassword, onSuccessfulSignIn)
    };

    const handleVerifyEmail = async (code: string, onCodeError: (code: boolean) => void) => {
        await AuthHandlers.verifyEmailWithCode(username, code, changeAuthState, onCodeError)
    }

    return (
        <Grid>
            <script src="https://accounts.google.com/gsi/client" async></script>
            {isMobile ? (
                <BackgroundImage
                    src="https://images.unsplash.com/photo-1453828423292-392a660a502f?q=80&w=3264&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    radius="sm"
                    style={{width: '100%', height: '101vh'}}
                >
                    <Overlay color="gray" opacity={0.5} zIndex={1}/> {/* Lower zIndex for the overlay */}
                    <Center style={{height: '100%'}}> {/* This will center the Paper horizontally and vertically */}
                        <Paper
                            mt="-10%"
                            radius="md"
                            p="xl"
                            withBorder
                            {...props}
                            style={{
                                width: '90%',
                                maxWidth: '600px',
                                zIndex: 2
                            }}  // maxWidth ensures the Paper won't be too wide
                        >
                            {authState === AuthStateOptions.Login && (
                                <Login
                                    isFirstLogin={false}
                                    changeAuthState={changeAuthState}
                                    handleEmailSignIn={handleEmailSignIn}
                                />
                            )}
                            {authState === AuthStateOptions.Register && (
                                <SignUp
                                    changeAuthState={changeAuthState}
                                    handleEmailSignUp={handleEmailSignUp}
                                />
                            )}
                            {authState === AuthStateOptions.LoginAfterRegistration && (
                                // todo: maybe I should be hiding the google & apple auth here?
                                <Login
                                    isFirstLogin={true}
                                    changeAuthState={changeAuthState}
                                    handleEmailSignIn={handleEmailSignIn}
                                />
                            )}
                            {authState === AuthStateOptions.SetPasswordAtFirstLogin && (
                                <PasswordChangeForm onSubmit={handlePasswordChangeSubmit}/>
                            )}
                            {authState === AuthStateOptions.Verify && (
                                <Verification handleVerification={handleVerifyEmail}/>
                            )}
                            {authState === AuthStateOptions.JoinWaitlist && (
                                <Waitlist changeAuthState={changeAuthState}/>
                            )}
                        </Paper>
                    </Center>
                </BackgroundImage>
            ) : (
                <BackgroundImage
                    src="https://images.unsplash.com/photo-1453828423292-392a660a502f?q=80&w=3264&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    radius="sm"
                    style={{width: '100%', height: '101vh'}}
                >
                    <Overlay color="gray" opacity={0.5} zIndex={1}/> {/* Lower zIndex for the overlay */}

                    <Center style={{height: '100%'}}>
                        <Paper
                            radius="md"
                            p="xl"
                            withBorder
                            style={{width: '90%', maxWidth: '750px', zIndex: 2}}
                        >
                            {authState === AuthStateOptions.Login && (
                                <Login
                                    isFirstLogin={false}
                                    changeAuthState={changeAuthState}
                                    handleEmailSignIn={handleEmailSignIn}
                                />
                            )}
                            {authState === AuthStateOptions.Register && (
                                <SignUp
                                    changeAuthState={changeAuthState}
                                    handleEmailSignUp={handleEmailSignUp}
                                />
                            )}
                            {authState === AuthStateOptions.LoginAfterRegistration && (
                                <Login
                                    isFirstLogin={true}
                                    changeAuthState={changeAuthState}
                                    handleEmailSignIn={handleEmailSignIn}
                                />
                            )}
                            {authState === AuthStateOptions.SetPasswordAtFirstLogin && (
                                <PasswordChangeForm onSubmit={handlePasswordChangeSubmit}/>
                            )}
                            {authState === AuthStateOptions.Verify && (
                                <Verification handleVerification={handleVerifyEmail}/>
                            )}
                            {authState === AuthStateOptions.JoinWaitlist && (
                                <Waitlist changeAuthState={changeAuthState}/>
                            )}
                        </Paper>
                    </Center>
                </BackgroundImage>
            )}
        </Grid>
    );
}