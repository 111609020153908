import React, { useState } from 'react';
import { useForm } from '@mantine/form';
import { Anchor, Button, Checkbox, Divider, Group, Stack, Text, TextInput, PasswordInput, Popover, Progress, Box, Alert } from '@mantine/core';
import { IconX, IconCheck } from '@tabler/icons-react';
import { AuthStateOptions } from './Authenticate';
import {SocialAuth} from './SocialAuth';
interface SignUpProps {
    handleEmailSignUp: (values: { firstName: string; lastName: string; email: string; password: string; terms: boolean }) => void;
    changeAuthState: (option: AuthStateOptions) => void;
}

const requirements = [
    { re: /[0-9]/, label: 'Includes number' },
    { re: /[a-z]/, label: 'Includes lowercase letter' },
    { re: /[A-Z]/, label: 'Includes uppercase letter' },
];

function PasswordRequirement({ meets, label }: { meets: boolean; label: string }) {
    return (
        <Text
            color={meets ? 'teal' : 'red'}
            style={{ display: 'flex', alignItems: 'center' }}
            mt={7}
            size="sm"
        >
            {meets ? <IconCheck style={{ width: 14, height: 14 }} /> : <IconX style={{ width: 14, height: 14 }} />} <Box ml={10}>{label}</Box>
        </Text>
    );
}

function getStrength(password: string) {
    let multiplier = password.length > 7 ? 0 : 1;

    requirements.forEach((requirement) => {
        if (!requirement.re.test(password)) {
            multiplier += 1;
        }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

const SignUp: React.FC<SignUpProps> = ({handleEmailSignUp, changeAuthState }) => {
    const [popoverOpened, setPopoverOpened] = useState(false);
    //const [password, setPassword] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const form = useForm({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            terms: false,
        },
        validate: {
            firstName: (val) => (val.length ? null : 'First name is required'),
            lastName: (val) => (val.length ? null : 'Last name is required'),
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
            password: (val) => (val.length >= 8 && /[a-z]/.test(val) && /[A-Z]/.test(val) && /[0-9]/.test(val) ? null : 'Password does not meet requirements'),
            confirmPassword: (val, values) => (val === values.password ? null : 'Passwords do not match'),
            terms: (val) => (val ? null : 'You must accept terms and conditions'),
        },
    });

    const checks = requirements.map((requirement, index) => (
        <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(form.values.password)} />
    ));

    const strength = getStrength(form.values.password);
    const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';

    const handleSubmit = (values: { firstName: string; lastName: string; email: string; password: string; confirmPassword: string; terms: boolean }) => {
        try {
            handleEmailSignUp(values);
            setFormSubmitted(true);
            setErrorMessage('');
        } catch (error) {
            setErrorMessage('An error occurred during sign-up. Please try again.');
        }
    };

    return (
        <>
            <Text size="lg" fw={500} mb="lg">
                Sign Up
                {/*Welcome to SortMyShoebox!*/}
            </Text>
            {/*todo: implement 3rd party oAuth*/}
            <Group grow mb="md" mt="md">
                <SocialAuth
                    onSuccess={(response: any) => {
                        console.log('Apple sign up success:', response);
                    }}
                    provider="Apple"
                    authType="Sign Up"
                />
                <SocialAuth
                    onSuccess={(response: any) => {
                        console.log('Google sign up success:', response);
                    }}
                    provider="Google"
                    authType="Sign Up"
                />
            </Group>
            <Divider label="Or sign up with email" labelPosition="center" my="lg" />
            {errorMessage && (
                <Alert title="Error" color="red" mb="lg">
                    {errorMessage}
                </Alert>
            )}
            <form onSubmit={form.onSubmit(handleSubmit)}>
                <Stack>
                    <Group grow align={"flex-start"}>
                        <TextInput
                            label="First Name"
                            withAsterisk
                            placeholder="Your first name"
                            value={form.values.firstName}
                            onChange={(event) => form.setFieldValue('firstName', event.currentTarget.value)}
                            error={form.errors.firstName}
                            radius="md"
                        />
                        <TextInput
                            label="Last Name"
                            withAsterisk
                            placeholder="Your last name"
                            value={form.values.lastName}
                            onChange={(event) => form.setFieldValue('lastName', event.currentTarget.value)}
                            error={form.errors.lastName}
                            radius="md"
                        />
                    </Group>
                    <TextInput
                        label="Email"
                        withAsterisk
                        placeholder="name@mail.com"
                        value={form.values.email}
                        onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                        error={form.errors.email}
                        radius="md"
                    />
                    <Popover opened={popoverOpened} position="bottom" width="target" transitionProps={{ transition: 'pop' }}>
                        <Popover.Target>
                            <div
                                onFocusCapture={() => setPopoverOpened(true)}
                                onBlurCapture={() => setPopoverOpened(false)}
                            >
                                <PasswordInput
                                    label="Password"
                                    withAsterisk
                                    placeholder="Your password"
                                    value={form.values.password}
                                    onChange={(event) => {
                                        form.setFieldValue('password', event.currentTarget.value);
                                    }}
                                    error={form.errors.password}
                                    radius="md"
                                />
                            </div>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <Progress color={color} value={strength} size={5} mb="xs" />
                            <PasswordRequirement label="Includes at least 8 characters" meets={form.values.password.length >= 8} />
                            {checks}
                        </Popover.Dropdown>
                    </Popover>
                    <PasswordInput
                        label="Confirm Password"
                        withAsterisk
                        placeholder="Confirm your password"
                        value={form.values.confirmPassword}
                        onChange={(event) => form.setFieldValue('confirmPassword', event.currentTarget.value)}
                        error={form.errors.confirmPassword}
                        radius="md"
                    />
                    <Checkbox
                        label="I accept terms and conditions"
                        checked={form.values.terms}
                        onChange={(event) => form.setFieldValue('terms', event.currentTarget.checked)}
                        error={form.errors.terms}
                    />
                </Stack>
                <Group justify="space-between" mt="xl">
                    <Anchor component="button" type="button" color="dimmed" size="xs" onClick={() => changeAuthState(AuthStateOptions.Login)}>
                        Already have an account? Sign In
                    </Anchor>
                    <Button type="submit" radius="xl">
                        Sign Up
                    </Button>
                </Group>
            </form>
        </>
    );
}

export default SignUp;