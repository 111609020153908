import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface AuthCallbackProps {
    onSuccessfulSignIn: () => void;
}

export const AuthCallback: React.FC<AuthCallbackProps> = ({ onSuccessfulSignIn }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        const handleCallback = async () => {
            const params = new URLSearchParams(location.search);
            const code = params.get('code');
            if (!code) {
                setError('Authorization code is missing');
                navigate('/login');
                return;
            }

            try {
                const response = await fetch('https://api.sortmyshoebox.com/auth/token-exchange', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include', // Include cookies
                    body: JSON.stringify({ code }),
                });

                if (response.ok) {
                    try {
                        const data = await response.json();

                        console.log(data);
                        console.log(data.body);

                        // Ensure data.body is parsed as a JSON object if it isn't already
                        const body = typeof data.body === 'string' ? JSON.parse(data.body) : data.body;
                        console.log(body);

                        // Store userId in localStorage
                        if (body.sub) {
                            localStorage.setItem('sub', body.sub);
                        } else {
                            console.warn("sub is missing from the response body.");
                        }

                        // Store access token in sessionStorage
                        if (body.accessToken && body.expiresIn) {
                            sessionStorage.setItem('accessToken', body.accessToken);
                            const expiresAt = Date.now() + body.expiresIn * 1000;
                            console.log(expiresAt);
                            sessionStorage.setItem('accessTokenExpiresAt', expiresAt.toString());
                        } else {
                            console.warn("accessToken is missing from the response body.");
                        }

                        // todo: only do if these exist? otherwise set to some type of default?
                        sessionStorage.setItem('email', body.userAttributes.email);
                        sessionStorage.setItem('firstName', body.userAttributes.firstName);
                        sessionStorage.setItem('lastName', body.userAttributes.lastName);
                        sessionStorage.setItem('profileUrl', body.userAttributes.picture);

                        // Call onSuccessfulSignIn callback
                        onSuccessfulSignIn();

                        // Navigate to the gallery page
                        navigate('/gallery');
                    } catch (error) {
                        console.error("Error processing response data:", error);
                    }
                } else {
                    throw new Error('Token exchange failed');
                }
            } catch (err) {
                setError('Authentication failed. Please try again.');
                navigate('/login');
            }
        };

        handleCallback();
    }, [location, navigate, onSuccessfulSignIn]);

    return (
        <div className="p-4 text-gray-600">
            {error ? <div className="text-red-600">Error: {error}</div> : 'Completing authentication...'}
        </div>
    );
};