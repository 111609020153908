import React, {useState, useEffect} from 'react';
import {Blockquote, Title, Flex, Paper, Button, Image, Divider, PaperProps, Group, Skeleton} from '@mantine/core';
import {IconInfoCircle} from "@tabler/icons-react";
import axios from 'axios';
import {AuthenticationResultType} from "@aws-sdk/client-cognito-identity-provider";
import ImageWithSkeleton from "../ImageWithSkeleton";
import {DuplicateImageGroup} from "../../types/ImageData";

interface DuplicatePhotoGroup {
    key: string;
    duplicates: string[]; // URLs of duplicate images
}

interface DuplicateProps extends PaperProps {
    userId: string,
    auth: AuthenticationResultType,
}

export function DeleteDuplicates({userId, auth}: DuplicateProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const [duplicateGroups, setDuplicateGroups] = useState<DuplicateImageGroup[]>([]);

    useEffect(() => {
        fetchDuplicateGroups();
    }, []);

    const fetchDuplicateGroups = async () => {
        setLoading(true);
        try {
            // const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/duplicates";
            // const id_token = auth.IdToken;
            //
            // const response = await axios.get(apiUrl, {
            //     params: {
            //         sub: userId,
            //         page: "1", // page.toString(),
            //         limit: "20" // limit.toString()
            //     },
            //     headers: {
            //         'Authorization': `Bearer ${id_token}`,
            //         'Content-Type': 'application/json'
            //     }
            // });
            //
            // const duplicates: DuplicatePhotoGroup[] = typeof response.data.body === 'string'
            //     ? JSON.parse(response.data.body)
            //     : response.data.body;
            //
            // console.log(duplicates);
            const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/duplicates/in-images";
            const response = await axios.post(apiUrl,
                {
                    sub: userId
                },
                {
                    headers: {
                        'Authorization': `Bearer ${auth.IdToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            console.log(response);
            console.log(JSON.parse(response.data.body));

            const duplicates: DuplicateImageGroup[] = typeof response.data.body === 'string'
                ? JSON.parse(response.data.body)
                : response.data.body;

            console.log(duplicates)
            setDuplicateGroups(duplicates);
        } catch (error) {
            console.error('Error fetching duplicate groups:', error);
        } finally {
            setLoading(false);
        }

    };

    // todo: implement fetch more duplicates. This should not be bad, duplicates are already paginated.
    //  I just need to start tracking page & limit, then use the same type of load more that I implemented
    //  in the IdentifyFaces.tsx file. For immediate future, I am just going to hope the user uploads no more
    //  than 20 sets of duplicates.

    // const mergeDuplicates = async (group: DuplicatePhotoGroup) => {
    //     // Optimistically remove the duplicate group
    //     const updatedDuplicateGroups = duplicateGroups.filter(dupGroup => dupGroup.key !== group.key);
    //     setDuplicateGroups(updatedDuplicateGroups);
    //
    //     try {
    //         let apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/duplicates"
    //         let id_token = auth.IdToken
    //         let sub = userId
    //
    //         const response = await axios.post(apiUrl, {sub: sub, key: group.key}, {
    //             headers: {
    //                 'Authorization': `Bearer ${id_token}`,
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         console.log('API response:', response.data);
    //
    //         // No need to update state again since we already removed it optimistically
    //     } catch (error) {
    //         console.error('Error merging grouped_images:', error);
    //
    //         // If API call fails, rollback by adding the group back
    //         setDuplicateGroups(currentGroups => [...currentGroups, group]);
    //
    //         // Optionally show an error to the user
    //         // You might want to add a toast or alert component to show this
    //         alert('Failed to merge duplicates. Please try again.');
    //     }
    // };

    const mergeDuplicates = async (group: DuplicateImageGroup) => {
            // Optimistically remove the duplicate group
            const updatedDuplicateGroups = duplicateGroups.filter(dupGroup => dupGroup.key !== group.key);
            setDuplicateGroups(updatedDuplicateGroups);
        try {
            const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/duplicates";
            await axios.post(apiUrl,
                {
                    sub: userId,
                    key: group.key
                },
                {
                    headers: {
                        'Authorization': `Bearer ${auth.IdToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            setDuplicateGroups(prev => prev.filter(g => g.key !== group.key));
        } catch (error) {
            console.error('Error merging duplicates:', error);

            // If API call fails, rollback by adding the group back
            setDuplicateGroups(currentGroups => [...currentGroups, group]);

            // Optionally show an error to the user
            // You might want to add a toast or alert component to show this
            alert('Failed to merge duplicates. Please try again.');
        }
    };

    // const mergeDuplicates = async (group: DuplicatePhotoGroup) => {
    //     try {
    //         let apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/duplicates"
    //         let id_token = auth.IdToken
    //         let sub = userId
    //
    //         const response = await axios.post(apiUrl, {sub: sub, key: group.key}, {
    //             headers: {
    //                 'Authorization': `Bearer ${id_token}`,
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         console.log('API response:', response.data);
    //         // After merging, remove the selected group from the state
    //         setDuplicateGroups(duplicateGroups.filter(dupGroup => dupGroup.key !== group.key));
    //     } catch (error) {
    //         console.error('Error merging grouped_images:', error);
    //     }
    // };

    const DuplicateGroupsSkeleton = () => {
        return (
            <div>
                {[...Array(3)].map((_, groupIndex) => (
                    <Paper
                        shadow="sm"
                        radius="lg"
                        withBorder
                        p="xl"
                        key={groupIndex}
                        mt={groupIndex > 0 ? "lg" : undefined}
                        style={{backgroundColor: '#f0f0f0'}}
                    >
                        <Skeleton height={28} width="40%" radius="xl"/>
                        <Divider mt='sm'/>
                        <Flex mt="lg" direction="row" wrap="wrap" gap={{base: '5px', sm: '5px'}} justify={{sm: 'left'}}>
                            {[...Array(2)].map((_, imageIndex) => (
                                <Skeleton
                                    key={imageIndex}
                                    width='calc((100% - 15px) / 3)'
                                    radius="md"
                                    style={{height: 'auto', aspectRatio: 1 / 1}}
                                />
                            ))}
                        </Flex>
                        <Group justify="flex-end" gap="sm" mt="lg">
                            <Skeleton height={36} width={100} radius="md"/>
                            <Skeleton height={36} width={140} radius="md"/>
                        </Group>
                    </Paper>
                ))}
            </div>
        );
    };

    return (
        <Flex direction='column'>
            <Title order={1}>Remove Duplicates</Title>
            <Divider mt='xs'/>
            <Blockquote color="blue" icon={<IconInfoCircle/>} mt='lg' mb='lg'>
                Deleting duplicates ensures a clutter-free photo collection by retaining only the highest quality image
                while merging all relevant metadata from duplicate photos. This process guarantees an organized library
                with no loss of information, allowing for a streamlined and enjoyable browsing experience.
            </Blockquote>
            {loading ? (
                <DuplicateGroupsSkeleton/>
            ) : (
                <div>
                    {duplicateGroups.map((group, index) => (
                        <Paper shadow="sm" radius="lg" withBorder p="xl" key={group.key}
                               mt={index > 0 ? "lg" : undefined} style={{backgroundColor: '#f0f0f0'}}>
                            <Title
                                order={2}>{"Duplicate Images:"}</Title>
                            <Divider mt='sm'/>
                            <Flex mt="lg" direction="row" wrap='wrap' gap={{base: '5px', sm: '5px'}}
                                  justify={{sm: 'left'}}>
                                {group.duplicates.map((image, idx) => (
                                    <div style={{
                                        position: 'relative',
                                        width: 'calc((100% - 15px) / 3)',
                                        aspectRatio: 1 / 1
                                    }}>
                                        <ImageWithSkeleton
                                            key={idx}
                                            src={image.thumbnail_url}
                                            alt={`Duplicate ${idx}`}
                                            onClick={() => {
                                            }}
                                        />
                                    </div>
                                ))}
                            </Flex>
                            <Group justify="flex-end" gap="sm" mt="lg">
                                <Button color="gray" variant="outline"
                                        // todo: obviously this should be a different handler than mergeDuplicates
                                        onClick={() => mergeDuplicates(group)}>Keep All</Button>
                                <Button color="blue"
                                        onClick={() => mergeDuplicates(group)}>
                                    Merge Duplicates
                                </Button>
                            </Group>
                        </Paper>
                    ))}
                </div>)}
        </Flex>
    );
};

export default DeleteDuplicates;