import {AuthenticationResultType} from '@aws-sdk/client-cognito-identity-provider';
import {AuthStateOptions} from './Authenticate';

export async function handleSignUp(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    setSession: (session: string) => void,
    setUsername: (username: string) => void,
    changeAuthState: (option: AuthStateOptions) => void
) {
    try {
        const response = await fetch('https://api.sortmyshoebox.com/auth/signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include', // allows the cookie to be set on successful sign in
            body: JSON.stringify({ email, password, firstName, lastName }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Sign-up failed.');
        }

        const data = await response.json();
        console.log(data);

        setUsername(email);
        changeAuthState(AuthStateOptions.Verify);
        trackTOSAgreement(data.userId);

    } catch (error) {
        console.error('Sign-up error:', error);
    }
}

export async function verifyEmailWithCode(
    username: string,
    verificationCode: string,
    changeAuthState: (option: AuthStateOptions) => void,
    onCodeError: (codeWrong: boolean) => void
) {
    try {
        const response = await fetch('https://api.sortmyshoebox.com/auth/verify-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, verificationCode }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Verification failed.');
        }

        changeAuthState(AuthStateOptions.LoginAfterRegistration);
    } catch (error) {
        onCodeError(true);
        console.error('Confirmation error:', error);
    }
}

export async function handlePasswordChangeSubmit(
    session: string,
    username: string,
    firstName: string,
    lastName: string,
    newPassword: string,
    onSuccessfulSignIn: (authResults: AuthenticationResultType, userResults: any) => void
) {
    try {
        const response = await fetch('https://api.sortmyshoebox.com/auth/change-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ session, username, firstName, lastName, newPassword }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Password change failed.');
        }

        const data = await response.json();
        console.log(data);

        if (data.authResults) {
            onSuccessfulSignIn(data.authResults, data.userResults);
        }
    } catch (error) {
        console.error('Password change error:', error);
        throw error;
    }
}

// Function to track TOS agreement in DynamoDB via API Gateway
const trackTOSAgreement = async (sub: string) => {
    const agreementData = {
        userId: sub,  // Use Cognito 'sub' as the user ID
        tosAgreementTimestamp: new Date().toISOString(),
        tosVersion: '1.0',  // Optional: Use the actual TOS version
    };

    try {
        const response = await fetch('https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/user/tos', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(agreementData),
        });

        if (!response.ok) {
            throw new Error('Failed to track TOS agreement');
        }
        console.log('TOS agreement tracked successfully');
    } catch (error) {
        console.error('Error saving TOS agreement:', error);
    }
};

