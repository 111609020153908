import React, {useState} from 'react';
import {useForm} from '@mantine/form';
import {Group, TextInput, PasswordInput, Button, Stack, Anchor, Text, Alert, Divider} from '@mantine/core';
import {AuthStateOptions} from './Authenticate';
import {SocialAuth} from "./SocialAuth";


interface LoginProps {
    isFirstLogin: boolean;
    handleEmailSignIn: (username: string, password: string, setErrorMessage: (message: string) => void, clearForm: () => void) => void;
    changeAuthState: (option: AuthStateOptions) => void;

}

const Login: React.FC<LoginProps> = ({isFirstLogin, handleEmailSignIn, changeAuthState}) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    //const [passwordChangeRequired, setPasswordChangeRequired] = useState(false);
    const form = useForm({
        initialValues: {
            email: '',
            password: '',
        },
        validate: {
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
            password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
        },
    });

    const clearForm = () => {
        form.setFieldValue('password', '')
    };

    return (
        <>
            <script src="https://accounts.google.com/gsi/client" async></script>
            <Text size="xl" fw={500} mb="md">
                Early Access Invite? Log In Here:
                {/*Welcome to SortMyShoebox!*/}
            </Text>
            {/*todo: implement 3rd party oAuth*/}
            <Group grow mb="md" mt="md">
                <SocialAuth
                    onSuccess={(response: any) => {
                        console.log('Apple login success:', response);
                    }}
                    provider="Apple"
                    authType="Sign In"
                />
                <SocialAuth
                    onSuccess={(response: any) => {
                        console.log('Google login success:', response);
                    }}
                    provider="Google"
                    authType="Sign In"
                />
            </Group>
            <Divider label="Or sign in with email" labelPosition="center" my="lg"/>
            {isFirstLogin && (
                <Alert title="Success!" color="green" mb="lg">
                    Your account has been created successfully! Login below to get started.
                </Alert>
            )}
            {errorMessage && (
                <Alert title="Error" color="red" mb="lg">
                    {errorMessage}
                </Alert>
            )}
            <form
                onSubmit={(event) => form.onSubmit(() => handleEmailSignIn(form.values.email, form.values.password, setErrorMessage, clearForm))(event)}>

                <Stack>
                    <TextInput
                        required
                        label="Email"
                        placeholder="name@mail.com"
                        value={form.values.email}
                        onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                        error={form.errors.email}
                        radius="md"
                    />
                    <PasswordInput
                        required
                        label="Password"
                        placeholder="Your password"
                        type="password"
                        value={form.values.password}
                        onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                        error={form.errors.password}
                        radius="md"
                    />
                </Stack>
                <Group justify="space-between" mt="xl">
                    <Anchor component="button" type="button" c="dimmed" size="xs"
                            onClick={() => changeAuthState(AuthStateOptions.Register)}>
                        Don't have an account yet? Sign Up
                    </Anchor>
                    <Button type="submit" radius="xl" color="blue">
                        Sign In
                    </Button>
                </Group>
            </form>
        </>
    );
}

export default Login;