import React from 'react';
import { Button, ButtonProps } from '@mantine/core';

interface SocialAuthProps {
    onSuccess: (tokens: any) => void;
    provider: 'Google' | 'Apple';
    authType: 'Sign In' | 'Sign Up'
}

// Google icon component from the commented code
function GoogleIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 256 262"
            style={{ width: '0.9rem', height: '0.9rem' }}
            {...props}
        >
            <path
                fill="#4285F4"
                d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
            />
            <path
                fill="#34A853"
                d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
            />
            <path
                fill="#FBBC05"
                d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
            />
            <path
                fill="#EB4335"
                d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
            />
        </svg>
    );
}

function AppleIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
    return (
        <svg viewBox="9 6 25 27" version="1.1" xmlns="http://www.w3.org/2000/svg"
             preserveAspectRatio="xMidYMid"
             style={{ width: '1.1rem', height: '1.1rem' }}>
            {/*<rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="39" height="44"></rect>*/}
            <path d="M19.8196726,13.1384615 C20.902953,13.1384615 22.2608678,12.406103 23.0695137,11.4296249 C23.8018722,10.5446917 24.3358837,9.30883662 24.3358837,8.07298156 C24.3358837,7.9051494 24.3206262,7.73731723 24.2901113,7.6 C23.0847711,7.64577241 21.6353115,8.4086459 20.7656357,9.43089638 C20.0790496,10.2090273 19.4534933,11.4296249 19.4534933,12.6807374 C19.4534933,12.8638271 19.4840083,13.0469167 19.4992657,13.1079466 C19.5755531,13.1232041 19.6976128,13.1384615 19.8196726,13.1384615 Z M16.0053051,31.6 C17.4852797,31.6 18.1413509,30.6082645 19.9875048,30.6082645 C21.8641736,30.6082645 22.2761252,31.5694851 23.923932,31.5694851 C25.5412238,31.5694851 26.6245041,30.074253 27.6467546,28.6095359 C28.7910648,26.9312142 29.2640464,25.2834075 29.2945613,25.2071202 C29.1877591,25.1766052 26.0904927,23.9102352 26.0904927,20.3552448 C26.0904927,17.2732359 28.5316879,15.8848061 28.6690051,15.7780038 C27.0517133,13.4588684 24.5952606,13.3978385 23.923932,13.3978385 C22.1082931,13.3978385 20.6283185,14.4963764 19.6976128,14.4963764 C18.6906198,14.4963764 17.36322,13.4588684 15.7917006,13.4588684 C12.8012365,13.4588684 9.765,15.9305785 9.765,20.5993643 C9.765,23.4982835 10.8940528,26.565035 12.2824825,28.548506 C13.4725652,30.2268277 14.5100731,31.6 16.0053051,31.6 Z" id="" fill="#000000" fillRule="nonzero"></path>
        </svg>
    );
}

export const SocialAuth: React.FC<SocialAuthProps> = ({ onSuccess, provider, authType }) => {
    const initiateOAuthFlow = () => {
        const cognitoDomain = 'shoebox.auth.us-west-2.amazoncognito.com';
        const clientId = 'rh8n1jh8ff99t3rupdnk63s7m';
        const redirectUri = `${window.location.origin}/login/callback`;

        const authorizeUrl = `https://${cognitoDomain}/oauth2/authorize?` +
            `identity_provider=${provider}&` +
            `response_type=code&` +
            `client_id=${clientId}&` +
            `redirect_uri=${encodeURIComponent(redirectUri)}`;

        console.log('Redirecting to:', authorizeUrl);
        window.location.href = authorizeUrl;
    };

    const Icon = provider === 'Google' ? GoogleIcon : AppleIcon;
    const buttonText = `${authType} with ${provider}`;

    return (
        <Button
            leftSection={<Icon />}
            variant="default"
            radius="xl"
            onClick={initiateOAuthFlow}
            fullWidth
        >
            {buttonText}
        </Button>
    );
};